@font-face {
  font-family: "Cairo-Regular";
  src: local("CairoRrgular"), url("./assets/fonts/Cairo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Cairo-Medium";
  src: local("CairoMedium"), url("./assets/fonts/Cairo-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Bold";
  src: local("CairoBold"), url("./assets/fonts/Cairo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-ExtraBold";
  src: local("ExtraBold"), url("./assets/fonts/Cairo-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-SemiBold";
  src: local("SemiBold"), url("./assets/fonts/Cairo-SemiBold.ttf") format("truetype");
}

:root {
  --primarymehroon: #B63436;
  --lightdark: #333333;
  --harddark: #1A1A1A;
  --white: #fff;
  --black: #000;
  --offwhite: #D9D9D9;
}


a,
p,
li,
span { 
  font-family: system-ui;
  text-decoration: none;
  padding: 0px;
}

/* :where(.css-dev-only-do-not-override-zl9ks2)[class^="ant-layout"],
:where(.css-dev-only-do-not-override-zl9ks2)[class*=" ant-layout"] {
  font-size: 0;
} */
.ant-layout {
  background: transparent;
}

a,
p,
li,
span,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  margin: 0;
  padding: 0;
}

 
.row {
  margin: 0;
}

 
  
 
.settitlepkg { 
  font-family: system-ui; 
  color: #003e70;
}  
.setheadingrow { 
  font-family: system-ui;
  font-weight: 600;
} 

.hdrtopbar {
  background: #f9f9f9;
}
header.setheaderweb.ant-menu-dark, header.setheaderweb.ant-menu-dark>.ant-menu {
  background: transparent;
}
header.setheaderweb.ant-layout-header {
  
  background: #229dce;
}
.headertours.ant-menu-dark, .headertours.ant-menu-dark>.ant-menu {
  background: transparent;
  display: flex;
  justify-content: center;
}

.hdrtopbar .topbrcol1 ul.topbarcontacts {
  color: #fff;
  list-style: none;
  gap: 8px;
  margin: 0;
  padding: 0;
}
.hdrtopbar  .topbrcol1 {
  display: flex;
  align-items: center;
}
 
.topbrcol2 .topbarmenus {
  display: flex;
  justify-content: flex-end;
}
 .setheaderweb .ant-menu-dark .ant-menu-item a{
  color: #fff !important;
}
.setheaderweb  .ant-menu-item-selected {
  color: #fff !important;

}
.headertours.ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover, 
.headertours.ant-menu-dark>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover, 
.headertours.ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) >.ant-menu-submenu-title:hover, 
.headertours.ant-menu-dark>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) >.ant-menu-submenu-title:hover {

  color:#909090;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: #002946; 
  border-radius: 3px; 
  width: 32px !important;
  height: 35px;
}
.carousel-caption {
  /* position: absolute; */
  /* right: 32%; */
  bottom: 27.5%;
  /* left: 32%; */
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  /* background: #00c2ffb0; */
  background: #054d6a8f;
  border-radius: 5px;
  
  border-left: solid 10px #229dce;
  padding: 10px;
  min-height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  left: 66%;
  right: 0%;
  transform: translate(-100%, -50%);
}
.cartmenutopbr .ant-badge-count {
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  top: 5px;
}
.seticonhome {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background: #ebe771;
  border-bottom-right-radius: 50px;
  width: 100%;
}
.seticonhomewhy {
font-size: 27px;
  display: flex;
  background: #dff7ff;
  border-radius: 50px;
  align-items: center;  
  padding: 6px 13px;
}
.whysportrow {
  background: #effbfd;
}
.whyustextheading span {
  font-size: 20px;
}
.setlblhomewhy {
  padding: 4px 5px 4px 0px;
  display: flex;
  align-items: center;
} 
.whysportrow h4{
  min-width: 174px; 
}

.slider-container .slick-next {
  right: 10px;
}
.slider-container .slick-prev {
  left: 10px;
} 

.slider-container .slick-next, 
.slider-container .slick-prev { 
  z-index: 1;
}

.slider-container .slick-dots {
  bottom: -42px; 
}

.card-setsider .imgset img {
  border-radius: 4px;
  box-shadow: 0px 0px 14px -4px #bcb320;
  margin: 4px 0px;
}
.card-setsider:hover .imgset img {
  border-radius: 22px;
  transform: 37%;
  transform: scale(1.04);
  transition: 1s;
}
.card-setsider .imgset {
  border-radius: 10px;
} 
.ant-btn-primary { 
  border: solid 2px #00c2ff;
  background: transparent;
  color: #00c2ff;
}
.slider-container .slick-dots li button:before {
  font-size: 7px;
  line-height: 15px;
  width: 17px;
  height: 17px;
  color: #003e70;
  border: solid;
}
.TdtCauroselPackage, .homecatpkgs {
  padding: 40px 0px 100px;
}


.TdtCarouselPopular {
  background: #f5f5f5;
  padding: 40px 0px 60px;
}
.TdtCarouselPopular  .card-setsider {
  margin-bottom: 25px;
}
.planecolumn {
  /* background: whitesmoke; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* padding-top: 10px; */
  /* background: white; */
  
  /* background: #f4fcff; */
}

/* .trvlpkg-content {
  min-height: 199px;
  box-shadow: 0px 3px 8px -5px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;   
  margin-top: -12px;
  display: flex;
  align-items: center;
  justify-content: center;

} */

.trvlpkg-content {
  
  min-height: 199px;
  box-shadow: 0px 3px 8px -5px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
}

.plncolmoverlays {
  position: absolute;
  background: linear-gradient(144deg, #00c2ff87, #ebe7719e);
  height: 100%;
  width: 100%;
  z-index: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.txtcontent-trvlpkg {
  position: absolute; 
  /* background: #00c2ffd6; */
  background: #090909ba;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  border-bottom: solid;
  border-right: solid;
  border-left: solid;
  border-color: #00c2ff;
}

.planecolumn:hover .imgset img {
  filter: grayscale(1); 
  transform: rotate(6deg);
  transition: 1s;  
}

.planecolumn:hover .plncolmoverlays {
  background: linear-gradient(144deg, #00c2ffcc, #ebe771d9);
}

.homecatpkgs .ant-ribbon-wrapper {
  margin: 11px;
}
.trvlduration span.lblset {
  padding-top: 1px;
}


.startdurationtxt, .startpamntfrtxt {
  position: absolute;
  bottom: 38px;
  display: block;
}
.startdurationtxt, .startpamntfrtxt {
  position: absolute;
  bottom: 39px;
  background: #0000008c;
  padding: 2px 11px;
  border-radius: 4px;
  color: #fff; 
}
.startdurationtxt {
  left: 16px;
}
.startpamntfrtxt {
  right: 16px;
}
.homecatpkgs .ant-card-body {
  padding-bottom: 6px !important;
}
.pmmentduratoin  {
  padding-top: 5px;
}

.homecatpkgs .ant-card-body:hover .startdurationtxt,

.homecatpkgs .ant-card-body:hover .startpamntfrtxt {
  display: block;
}
.TdtWhoWeAre {
  background: #fff9e8;
  padding: 140px 0px;
  border-top-right-radius: 245px;
}
.TdtWhoWeAre li.listitem {
  font-size: 16px;
}
 
.TdtWhoWeAre li.listitem .lblset span {
    font-size: 16px;
    font-weight: 700;
    font-family: system-ui;
    line-height: 1.9;
}
.TdtWhoWeAre .whovr-col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .TdtWhoWeAre .whovr-col1 span.iconset {
  margin-top: 5px;
} */
 
 
.ftrcrdlst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid #f2f2f2;
  padding: 10px;
}

.imgcardlist {
  width: 294px;
}
 
.actionscardlist {
  width: 60px;
  display: flex;
  align-items: center;
  background: #f1f8ff;
  justify-content: center;
  flex-direction: column;
}
.cardlstbookbtn {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 13px;
}

.desftr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tourcardlist {
  box-shadow: 0px 0px 14px -10px;
  border-radius: 6px;
  overflow: hidden;
}
.descrdlst {
  padding: 10px;
}
.imgcardlist{  
    display: flex;
    background-position: center;
    background-size: cover;
}
.actionscardlist span.iconset {
  background: #00c2ff;
  margin: 1px;
  padding: 5px;
  color: #fff;
  border-radius: 4px; 
  display: flex;
}
.stylelist li {
  border-bottom: none !important;
}
.whovr-col2 {
  display: flex;
  align-items: center;
}
.col2menu2 {
  /* border-right: solid 1px #aeaeae; */
  /* border-right: solid 1px #c5c5c5; */
}

.divider-footer .ant-divider-inner-text {
  gap: 20px;
  display: flex;
}
.divider-footer span.iconset {
  color: #fff;
  font-size: 24px;
}

:where(.css-dev-only-do-not-override-zl9ks2).ant-divider-horizontal.ant-divider-with-text::after,
:where(.css-dev-only-do-not-override-zl9ks2).ant-divider-horizontal.ant-divider-with-text::before {
  border-block-start: 2px solid transparent;
  border-block-start-color: #cbd6e2;
}
footer { 
  background-position: center;
  background-size: cover;
}
.ant-divider.css-dev-only-do-not-override-zl9ks2.ant-divider-vertical.ant-divider-with-text.ant-divider-with-text-center.divider-footer {
  height: 100%;
  background: #cbd6e2; 
}

.footrmenu3 {
  display: flex; 
}
.footrmenu3 .ant-divider.css-dev-only-do-not-override-zl9ks2.ant-divider-vertical.ant-divider-with-text.ant-divider-with-text-center.divider-footer {
  height: 91%;
  margin-left: 0;
  margin: auto 14px;
}
.footertopmain .ant-list-items a {
  /* color: #c5c5c5; */
  color: #cbd6e2;
  
}
.footertopmain li.ant-list-item {
  padding-bottom: 0px;
}

.footmain  { 
background-position: center;
background-size: cover;
background-attachment: fixed;
}
.ftroverlay {
  height: 100%;
  width: 100%;
  background: #001529ab;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.footr-content {
  z-index: 1;
  position: relative;
}
del {
  color: #c90e0e;
}
span.amountcardlist {
  font-weight: 700; 
  font-size: 20px;
}
span.touramount { 
  font-weight: 700; 
}
.copyrightcontrn .text-light {
  color: #dadada !important;
}

.copyrightcontrn a {
  color: #00c2ff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.banertxtcontn .bnrdescr {
  font-size: 12px;
  font-weight: 500;
}

.sidebartrours {
  position: sticky !important;
  top: 67px;
  box-shadow: 0px 0px 11px -7px;
  padding: 24px 24px; 
  border-radius: 9px;
}
.amoutcategoryfltr .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.amoutcategoryfltr label {
  background: #f1f1f1;
  margin: 2px 0px;
  padding: 4px;
  border-radius: 3px;
}
.bannerBread{
    background-size: cover;
    background-position: center;
}
.ovelaybred {
  width: 100%;
  height: 100%;
  background: linear-gradient(116deg, #0000000d, #910a0a00);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.bannerbredcontent {
  z-index: 1;
  position: inherit;
}
.bannerbredcontent h4.text-light {
  width: fit-content;
  background: #229dce;
  padding: 10px 22px;
  border-radius: 3px;
  font-size: 16px;
}
.abouttourpkg .ant-collapse-header, .faqtourpkg .ant-collapse-header {
  background: #e3f2fd;
}

.abouttourpkg .ant-collapse , 
.abouttourpkg  .ant-collapse .ant-collapse-content{
  border-color: #f5f5f5;
} 

.faqtourpkg .ant-collapse , 
.faqtourpkg  .ant-collapse .ant-collapse-content{
  border-color: #f5f5f5;
}


/* .tourcardlist::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 0%;
  left: 50%;
  top: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 1;
}

.tourcardlist:hover::after {
  height: 250%;
  transition: all 600ms linear;
  background-color: transparent; 
} */

h1.detailpakglbl {
  font-size: 22px; 
}
.detailpakgrating svg {
  color: gold;
}

.revstxt {
  padding-left: 4px;
  padding-top: 1px;
}
.revstxttags {
  color: #00c2ff;
}
.listcrdcontent .slider-container .slick-dots {
  bottom: -16px;
}
.servsdetailpg  svg {
  font-size: 26px;
  padding-right:5px ;
  margin-bottom: 3px;
}
.servsdetailpg1 svg {
  color: #0288D1;
}
.servsdetailpg11 svg {
  color: red;
}
.servsdetailpg2 svg {
  color: green;
}
.servsdetailpg22 svg {
  color: yellow;
}
.servsdetailpg3 svg {
  color: purple;
}
.servsdetailpg33 svg {
  color: pink;
}
.servsdetailpg4 svg {
  color: rgb(0, 253, 253);
}
.servsdetailpg44 svg {
  color: rgb(13, 255, 0);
}
.detaipageresons .col-sm-12 {
  flex-direction: column;
}
.detaipageresons h4.whyustextheading br {
  display: none;
}
.drtsfrsidbar .whysportrow {
  background: #f8feff;
  border-right: 56px solid #E0F7FA;
}
.pkgdtlrevies svg {
  color: red;
  background: #00c2ff;
  color: #fff;
  padding: 3px;
  font-size: 16px;
  border-radius: 3px;
}

.mt-m10 {
  margin-top: -10px;
}
.mt-m15 {
  margin-top: -15px;
}
.mt-m20 {
  margin-top: -20px;
}
.pkgdtlrevies li.ant-list-item {
  background: #effbfd;
  margin: 5px 0px;
  border-radius: 4px;
}
.pkgdtlrevies .ant-list-item-meta-avatar {
  border: solid 1px #b3e6ed;
  border-radius: 100px;
  padding: 4px;
}

.contactsinglecard .headercontact {
  background: #e0f7fa;
  padding: 15px 8px; 
  text-align: center;
}
.contactsinglecard .contactsnglfootr {
  background: #e0f7fa;
  padding: 8px;
  display: flex;
  justify-content: center;
}
.contactsinglecard .contentcontact {
  padding: 20px;
}
.contactsinglecard {
  box-shadow: 0px 0px 8px -4px #ababab;
  border-radius: 4px;
  overflow: hidden;
}

.liststyle {
  list-style: none;
  gap: 5px;
  padding: 0px;
  margin: 0;
}
.contactsinglecard .contactsnglfootr svg {
  font-size: 23px;
  margin: 4px;
  background: #00c2ff;
  color: #effbfd;
  padding: 4px;
  border-radius: 3px;
}
.contactsinglecard .contentcontact {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactsinglecard .contentcontact div {
  text-align: center;
  display: flex;
  justify-content: center;
}
.contactsinglecard .contentcontact a {
  font-size: 18px;
  color: #000; 
}
.ant-drawer-header {
  background: #2196f3;
  padding: 10px !important;
}
.ant-drawer-title {
  color: #fff !important;
}
.misionvision {background-color: rgb(255, 255, 255);
  
  overflow: hidden;
  border-radius: 16px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}

.box-shadow-tdt {
  box-shadow: rgba(0, 0, 0, 0.03) 0px -1px 0px 0px, rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 4px 2px -2px, rgba(0, 0, 0, 0.16) 0px 4px 8px -1px;
}

.whatmakesdifferent {
  background: #f5f5f5;
}

.ant-collapse-item-active .ant-collapse-header {
  background: #00c2ff;
}
.ant-collapse-item-active .ant-collapse-header {
  color: #fff !important;
}
.ant-collapse-item {
  border-bottom: 1px solid #f1f1f1;
}
.ant-collapse {
  border-bottom: 1px solid #f1f1f1;
}
/* 
.divider-footer {
  background: #d9d9d9;
  height: 100%;
} */

.ant-divider {  
  border-color: #fff !important;
  height: 100%;

}
 
.tblfooterdetail {
  position: absolute;
  bottom: 0;
  width: 100%;
  bottom: -24px;
  position: sticky;
  background: #fafafa;
  padding: 15px;
  border-radius: 5px;
}
.chkoutpage .drtsfrsidbar .whysportrow {
  background: #f8feff;
  border-right: 0px solid #E0F7FA;
}
.loginpage .container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} 
.colonelogin p {
  text-align: center;
  padding-top: 15px;
}
.loginpage.container-fluid {
  background: #e5f9ff;
  background-image: url('../public//assets//bannerLOGIN.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

/* .colonelogin {
  background: #00c2ff24;
  border-radius: 5px;
  box-shadow: 0px 0px 8px -3px;
} */

.colonelogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: solid 3px #9ee8ff;
}
.loginpage .justify-content-center.row {
  background: #e4f8ffed;
  /* margin: 40px 0px; */
  border-radius: 12px;
  box-shadow: 0px 0px 8px -3px;
  padding: 58px 18px;
}
.adminpanelhdr {
  background: #133a46 !important;
}
.ant-menu-dark, .ant-menu-dark>.ant-menu { 
  background: transparent;
} 
.ant-layout .ant-layout-sider { 
  background: #1e4653; 
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, .ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #133a46;
}
.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #0f323d;
}

.ant-layout .ant-layout-sider-trigger { 
  background: #133a46; 
}

 .ant-layout-footer {
  padding: 13.5px 50px;
  color: rgb(255 255 255 / 88%);
  font-size: 14px;
  background: #1e4653;
  position: absolute;
  bottom: 0;
  position: sticky;
}

.homedashbrcard {
  box-shadow: 0px 0px 11px -4px;
  /* background: rgb(255, 255, 255); */
  background: linear-gradient(165deg, rgb(52 87 98) 53%, rgb(71 100 109) 53%);
}

.homedashbrcard h5 { 
  color: #f3f3f3;
}

.ant-drawer-header {
  background: #1e4653; 
}

button.ant-drawer-close .anticon svg { 
  color: #fff;
}

.cardsbtns {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TdtTravelPkg {
  background: #f6fbfb;
  padding: 70px 0px 60px !important;
}
.cardsbtns span {
  display: flex;
  align-items: center !important; 
  color: #fff;
}
 
li.ant-menu-overflow-item.ant-menu-submenu-horizontal span.anticon.anticon-ellipsis svg {
  background: #00c2ff;
  border: solid 1px #001529;
  font-size: 26px;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  margin-top: 20px;
}

/* :where(.css-dev-only-do-not-override-1uq9j6g).ant-layout-header .ant-menu {
  line-height: 5.55;
} */

label.customlabel {
  margin-bottom: 10px;
}
.ckeditorpkgdtl ul {
  padding: 6px 16px;
}
.ckeditorpkgdtl b, .ckeditorpkgdtl strong {
  font-weight: inherit;
}
.listcrdcontent a.tourcardlist.d-flex.mb-3 {
  color: inherit;
}

.cntctpginq a {
  color: #00c2ff;
  font-weight: 600;
  padding: 7px;
  display: flex;
}
.whovr-col1-home ol {
  padding-left: 17px;
  line-height: 31px;
  margin: 0;
}
.hdrtopbar .topbrcol1 li {
  color: #229dce;
  font-weight: 500;
}.topbrcol2 a {
  color: #229dce !important;
  font-weight: 500;
}
a.cartmenutopbr .ant-avatar {
  background: #229dce;
}
@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1170px;
  }
} 
.topbarmenus svg.ant-menu-item-icon {
  color: #229dce !important;
  font-size: 19px !important;
}
.othermenu span.ant-menu-title-content {
  color: #fff;
}.topbrcol2.d-flex.align-items-center.col-md-6.col-sm-12 .ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected {
  background: aliceblue;
}

.cta-home .position-absolute.d-flex.gap-4 {
  top: 46px;
  left: 31%;
  display: flex;
  align-content: center;
  align-items: center;
}
.cta-home {
  /* background: #cccccc; */
  background-image: url(http://localhost:3000/assets/footerbg.png);
  background-position: center;
  background-attachment: fixed;
}
.cta-home h5 {
  color: #fff;
  font-family: ui-serif;
  font-size: 33px;
}
.misionvision img {
  height: 118px !important;
  padding: 22px 10px 6px 10px;
}
.aboutuswhtmkdiff .text-center.px-sm-1.px-md-5.py-3 {
  background: #d5e8ef;
  /* gap: 2px; */
  border: solid 1px #fff;
}
.aboutuswhtmkdiff .text-center.px-sm-1.px-md-5.py-3:hover {
  background: #fff; 
  zoom: 1;
  transition: 1s;
}
.privacypoliscy h4 {
  font-size: 16px;
  padding: 14px 0px 3px 0px;
  color: #229dce;
}

.imgslidsimgs img.ant-image-img {
  width: 61px;
  margin: 3px;
}
.descrdlst h5 {
  font-size: 16px;
  color: #00c2ff;
}.descrdlst p {
  font-size: 13px;
}
.desftr .ftrcrdlst {
  padding: 5px 10px;
}
.desftr .ftrcrdlst button {
  height: 24px;
  font-size: 12px;
  padding: 7px;
  border-radius: 3px;
}.desftr .ftrcrdlst span.amountcardlist {
  font-size: 16px;
}

.desftr .ftrcrdlst {
  padding: 5px 10px;
  font-size: 12px;
}
.showhidebtn {
  padding: 6px 19px !important;
  font-size: 12px;
  margin-top: 3px;
}
li.addiotnaloptionslist {
  list-style: none;
  /* padding: 0px; */
}
span.addiotnaloptionslistnumber {
  background: #00c2ff;
  /* padding: 10px; */
  width: 30px;
  height: 30px;
  display: inline-flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: solid 3px #fff;
  color: #fff; 
  margin-right: 6px;
}
li.addiotnaloptionslist {
  list-style: none;
  /* padding: 0px; */
  background: #00c2ff1f;
  color: #fff;
  border-radius: 50px;
  width: 320px;
  font-weight: 600;
  color: #00c2ff;
  display: flex;
  align-items: center; 
  margin: 6px 0px;

}
.reviewscommentsingpkg .d-flex.justify-content-between.pt-3.pb-2 {
  background: #00c2ff30;
  padding: 12px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.reviewscommentsingpkg .ant-btn-primary {
  background: #00c2ff;
  color: #fff;
  border: solid;
}
.pkginfodrwr h5  {
  color: #00c2ff;
  border-bottom: solid 1px #bcd4f3;
  margin-bottom: 17px;
}
.pkginfodrwr ul {
  list-style: none   !important; 
}